import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, lastValueFrom } from 'rxjs';

declare global {
  interface Window {
    // https://docs.aws.amazon.com/waf/latest/developerguide/waf-js-challenge-api-get-token.html
    AwsWafIntegration: {
      hasToken: () => boolean;
      getToken: () => Promise<string>;
    };
  }
}

@Injectable()
export class WafTokenInterceptor implements HttpInterceptor {
  public intercept = (req: HttpRequest<unknown>, next: HttpHandler) => from(this.handle(req, next));

  public async handle(req: HttpRequest<unknown>, next: HttpHandler) {
    const { url } = req;
    const hasToCheckToken = ['startOnboarding', 'signupuser', 'registrationconfirmation', 'resendConfirmationMail'].some((path) =>
      url.endsWith(path),
    );
    if (hasToCheckToken && !window.AwsWafIntegration?.hasToken()) {
      try {
        console.warn('WAF token is missing, trying to get it...');
        const token = await window.AwsWafIntegration?.getToken();
        if (token) {
          req = req.clone({
            setHeaders: {
              'x-aws-waf-token': token,
            },
          });
        }
      } catch (e) {
        // fails, but we don't want to block the request
        console.error('WAF Token error:', e);
        return await lastValueFrom(next.handle(req));
      }
    }

    return await lastValueFrom(next.handle(req));
  }
}
